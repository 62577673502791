import {
  DashboardOutlined,
  EuroCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "sidenav.home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "reports",
    path: `${APP_PREFIX_PATH}/reports`,
    title: "sidenav.reports",
    icon: "",
    breadcrumb: false,
    submenu: [
      {
        key: "reports-commissions",
        path: `${APP_PREFIX_PATH}/reports/commissions`,
        title: "sidenav.reports.commissions",
        icon: EuroCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "reports-clicks",
        path: `${APP_PREFIX_PATH}/reports/clicks`,
        title: "sidenav.reports.clicks",
        icon: LinkOutlined,
        breadcrumb: true,
        submenu: [],
      },
      // {
      // 	key: "reports-networks",
      // 	path: "",
      // 	title: "sidenav.reports.networks",
      // 	icon: DeploymentUnitOutlined,
      // 	breadcrumb: true,
      // 	submenu: [
      // 		{
      // 			key: "reports-networks-list",
      // 			path: `${APP_PREFIX_PATH}/reports/networks/list`,
      // 			title: "sidenav.reports.networks.list",
      // 			icon: "",
      // 			breadcrumb: true,
      // 			submenu: [],
      // 		},
      // 	],
      // },
    ],
  },
  {
    key: "accounting",
    path: `${APP_PREFIX_PATH}/accounting`,
    title: "sidenav.accounting",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "accounting-credits-list",
        path: `${APP_PREFIX_PATH}/credits`,
        title: "sidenav.accounting.credits",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "accounting-credits-income",
        path: `${APP_PREFIX_PATH}/credits-income`,
        title: "sidenav.accounting.credits-income",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "accounting-commissions",
        path: `${APP_PREFIX_PATH}/accounting/commissions`,
        title: "sidenav.accounting.commissions",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "accounting-commission-adjustments",
        path: `${APP_PREFIX_PATH}/accounting/commission-adjustments`,
        title: "sidenav.accounting.commission.adjustments",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "accounting-credits-generate",
        path: `${APP_PREFIX_PATH}/credits/generate`,
        title: "sidenav.accounting.credits.generate",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "accounting-eokoko",
        path: `${APP_PREFIX_PATH}/accounting/eokoko`,
        title: "sidenav.accounting.eokoko",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "publisher",
    path: "",
    title: "sidenav.publisher",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "publisher-list",
        path: `${APP_PREFIX_PATH}/publisher/list`,
        title: "sidenav.publisher.list",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "publisher-adspaces",
        path: `${APP_PREFIX_PATH}/publisher/adspaces`,
        title: "sidenav.publisher.adspaces",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "advertiser",
    path: "",
    title: "sidenav.advertiser",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "advertiser-list",
        path: `${APP_PREFIX_PATH}/advertiser/list`,
        title: "sidenav.advertiser.list",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "advertiser-network",
        path: `${APP_PREFIX_PATH}/advertiser/network`,
        title: "sidenav.advertiser.network",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "advertiser-links",
        path: `${APP_PREFIX_PATH}/advertiser/links`,
        title: "sidenav.advertiser.links",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "advertiser-showcase",
        path: `${APP_PREFIX_PATH}/advertiser/showcase`,
        title: "sidenav.advertiser.showcase",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "redirect",
    path: `${APP_PREFIX_PATH}/redirect`,
    title: "sidenav.redirect",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "redirect-networkpriority",
        path: `${APP_PREFIX_PATH}/redirect/networkpriority`,
        title: "sidenav.redirect.networkpriority",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "redirect-refererblock",
        path: `${APP_PREFIX_PATH}/redirect/refererblock`,
        title: "sidenav.redirect.refererblock",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "system",
    path: `${APP_PREFIX_PATH}/system`,
    title: "sidenav.system",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "system-admin",
        path: `${APP_PREFIX_PATH}/system/admin`,
        title: "sidenav.system.user",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "system-admin-list",
            path: `${APP_PREFIX_PATH}/system/admin/list`,
            title: "sidenav.system.user.list",
            icon: DashboardOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
