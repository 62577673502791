import fetch from "auth/FetchInterceptor";

const adindexService = {};

adindexService.getRefererBlockList = function(data) {
  return fetch({
    url: "/admin/misc/refererBlockList",
    method: "post",
    data: data,
  });
};

adindexService.setRefererBlockList = function(data) {
  return fetch({
    url: "/admin/misc/refererBlockList",
    method: "put",
    data: data,
  });
};

adindexService.getCustomerCreditTransferInitiation = function(data) {
  return fetch({
    url: "/admin/credits/customerCreditTransferInitiation",
    method: "post",
    data: data,
  });
};

adindexService.setCreditsToCleared = function(data) {
  return fetch({
    url: "/admin/credits/setToCleared",
    method: "put",
    data: data,
  });
};

adindexService.getCredits = function(data, arraybuffer = false) {
  return fetch({
    url: "/admin/credits",
    method: "post",
    data: data,
    responseType: arraybuffer ? "arraybuffer" : "basic",
  });
};
adindexService.setCommissions = function(data) {
  return fetch({
    url: "/admin/commissions",
    method: "put",
    data: data,
  });
};

adindexService.getSystemUserRights = function(data) {
  return fetch({
    url: "/admin/system/user/rights",
    method: "post",
    data: data,
  });
};
adindexService.getSystemUser = function(data) {
  return fetch({
    url: "/admin/system/user",
    method: "post",
    data: data,
  });
};
adindexService.setSystemUser = function(data) {
  return fetch({
    url: "/admin/system/user",
    method: "put",
    data: data,
  });
};
adindexService.setSystemUserActive = function(data) {
  return fetch({
    url: "/admin/system/user/active",
    method: "put",
    data: data,
  });
};
adindexService.getRole = function(data) {
  return fetch({
    url: "/admin/system/role",
    method: "post",
    data: data,
  });
};
adindexService.resetSystemUserPassword = function(data) {
  return fetch({
    url: "/admin/system/user/resetPassword",
    method: "post",
    data: data,
  });
};

adindexService.getAdvertiser = function(data, signal) {
  return fetch({
    url: "/admin/advertiser",
    method: "post",
    data: data,
    signal: signal,
  });
};

adindexService.getNetwork = function(data) {
  return fetch({
    url: "/admin/network",
    method: "post",
    data: data,
  });
};
adindexService.getNetworkPriority = function(data) {
  return fetch({
    url: "/admin/misc/networkPriority",
    method: "post",
    data: data,
  });
};
adindexService.setNetworkPriority = function(data) {
  return fetch({
    url: "/admin/misc/networkPriority",
    method: "put",
    data: data,
  });
};
adindexService.getAdvertiserNetwork = function(data) {
  return fetch({
    url: "/admin/advertiser/network",
    method: "post",
    data: data,
  });
};
adindexService.getReportNetworkCommissions = function(data) {
  return fetch({
    url: "/admin/report/network/commissions",
    method: "post",
    data: data,
  });
};
adindexService.getAdvertiserLinks = function(data) {
  return fetch({
    url: "/admin/advertiser/link",
    method: "post",
    data: data,
  });
};
adindexService.setAdvertiserLink = function(data) {
  return fetch({
    url: "/admin/advertiser/link",
    method: "put",
    data: data,
  });
};
adindexService.deleteAdvertiserLink = function(data) {
  return fetch({
    url: "/admin/advertiser/link",
    method: "delete",
    data: data,
  });
};
adindexService.setAdvertiser = function(data) {
  return fetch({
    url: "/admin/advertiser",
    method: "put",
    data: data,
  });
};

adindexService.getPreviewCredits = function(data) {
  return fetch({
    url: "/admin/accounting/previewCredits",
    method: "post",
    data: data,
  });
};
adindexService.createCredits = function(data) {
  return fetch({
    url: "/admin/accounting/createCredits",
    method: "put",
    data: data,
  });
};
adindexService.createAdjustments = function(data) {
  return fetch({
    url: "/admin/accounting/createAdjustments",
    method: "put",
    data: data,
  });
};
adindexService.cancelCredits = function(data) {
  return fetch({
    url: "/admin/accounting/cancelCredits",
    method: "put",
    data: data,
  });
};
adindexService.getEokokoShare = function(data) {
  return fetch({
    url: "/admin/accounting/eokokoShare",
    method: "post",
    data: data,
  });
};
adindexService.getCreditsIncome = function(data) {
  return fetch({
    url: "/admin/accounting/creditsIncome",
    method: "post",
    data: data,
  });
};

adindexService.getReportClickIns = function(data, signal) {
  return fetch({
    url: "/admin/report/clickIns",
    method: "post",
    data: data,
    signal: signal,
  });
};

adindexService.getReportClickOuts = function(data, signal) {
  return fetch({
    url: "/admin/report/clickOuts",
    method: "post",
    data: data,
    signal: signal,
  });
};

adindexService.getReportClickOutResults = function(data, signal) {
  return fetch({
    url: "/admin/report/clickOutResults",
    method: "post",
    data: data,
    signal: signal,
  });
};

adindexService.getReportClickInAdspaces = function(data, signal) {
  return fetch({
    url: "/admin/report/clickInAdspaces",
    method: "post",
    data: data,
    signal: signal,
  });
};

adindexService.getPublisher = function(data) {
  return fetch({
    url: "/admin/publisher",
    method: "post",
    data: data,
  });
};
adindexService.setPublisher = function(data) {
  return fetch({
    url: "/admin/publisher",
    method: "put",
    data: data,
  });
};
adindexService.setPublisherBilling = function(data) {
  return fetch({
    url: "/admin/publisher/information/billing",
    method: "put",
    data: data,
  });
};
adindexService.setPublisherInformation = function(data) {
  return fetch({
    url: "/admin/publisher/information",
    method: "put",
    data: data,
  });
};
adindexService.resetPublisherPassword = function(data) {
  return fetch({
    url: "/admin/publisher/information/resetPassword",
    method: "post",
    data: data,
  });
};
adindexService.disablePublisher = function(data) {
  return fetch({
    url: "/admin/publisher/disable",
    method: "put",
    data: data,
  });
};
adindexService.setPublisherExcludedNetworks = function(data) {
  return fetch({
    url: "/admin/publisher/excludedNetworks",
    method: "put",
    data: data,
  });
};
adindexService.setPublisherExcludedAdvertiser = function(data) {
  return fetch({
    url: "/admin/publisher/excludedAdvertiser",
    method: "put",
    data: data,
  });
};
adindexService.getPublisherExcludedNetworks = function(data) {
  return fetch({
    url: "/admin/publisher/excludedNetworks",
    method: "post",
    data: data,
  });
};
adindexService.getPublisherExcludedAdvertiser = function(data) {
  return fetch({
    url: "/admin/publisher/excludedAdvertiser",
    method: "post",
    data: data,
  });
};
adindexService.getPublisherAdspaces = function(data, signal) {
  return fetch({
    url: "/admin/publisher/adspaces",
    method: "post",
    data: data,
    signal: signal,
  });
};

adindexService.getReportCommissions = function(data, signal) {
  return fetch({
    url: "/admin/report/commissions",
    method: "post",
    data: data,
    signal: signal,
  });
};
adindexService.getReportConversions = function(data, signal) {
  return fetch({
    url: "/admin/report/conversions",
    method: "post",
    data: data,
    signal: signal,
  });
};

export default adindexService;
